import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "number-enchar-encrypt"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ellipsis_text = _resolveComponent("ellipsis-text");
  const _directive_decode_font = _resolveDirective("decode-font");
  return _openBlock(), _createBlock(_component_ellipsis_text, {
    "content-class": "empty-placeholder"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infos, (info, index) => {
      return _openBlock(), _createElementBlock(_Fragment, {
        key: index
      }, [_withDirectives((_openBlock(), _createElementBlock("span", _hoisted_1, [_createTextVNode(_toDisplayString(info), 1)])), [[_directive_decode_font]]), index < _ctx.infos.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_2, " / ")) : _createCommentVNode("", true)], 64);
    }), 128))]),
    _: 1
  });
}
import { computed, defineComponent, inject, reactive } from 'vue';
import { useRoute } from 'vue-router';
import ContactInfo from './components/ContactInfo.vue';
import Customs from './components/Customs.vue';
import CompanyInfo from './components/CompanyInfo.vue';
import SectionHeader from '@/components/SectionHeader.vue';
import { useRequest } from '@/base/hooks/useGoodRequest';
import { useEncryptFont } from '@/base/plugin/encryptFont';
import { getUsBuyerdbCompanyDetail } from '@/base/api/buyerdb/company/methods';
// import {useLog} from '@/base/log/tjLog';
// import {useLogin} from '@/base/hooks/useLogin';
// import {ACCOUNT_TYPE} from '@/base/api/user/type';
import { scLog } from '@/base/log/sclog';
import { TRIAL_TYPE_ENUM } from '@/views/components/Freetrial/types';
import { INJECT_KEY__TO_TRIAL } from '@/views/components/Freetrial/injectKeys';
export default defineComponent({
  components: {
    SectionHeader,
    CompanyInfo,
    ContactInfo,
    Customs
  },
  name: 'UsCustomsDataDetail',
  setup() {
    // const {send} = useLog();
    const route = useRoute();
    const {
      font
    } = useEncryptFont('number-enchar-encrypt');
    // const {user} = useLogin();
    const companyId = route.params.id;
    let sendCompanyName;
    const {
      isLoading,
      data: companyDetailData
    } = useRequest(getUsBuyerdbCompanyDetail, {
      params: {
        companyId,
        fc: font === null || font === void 0 ? void 0 : font.code
      },
      onSuccess: () => {
        scLog('click', {
          elem: 'us_company_infor_show'
        });
        // send('us_company_infor_show', {
        //     companyName: sendCompanyName.value,
        // });
      }
    }).refsState;
    const contacts = computed(() => {
      var _companyDetailData$va;
      if (((_companyDetailData$va = companyDetailData.value) === null || _companyDetailData$va === void 0 ? void 0 : _companyDetailData$va.contact) instanceof Array) {
        return companyDetailData.value.contact;
      }
      return [];
    });
    sendCompanyName = computed(() => {
      var _companyDetailData$va2;
      const companyName = (_companyDetailData$va2 = companyDetailData.value) === null || _companyDetailData$va2 === void 0 ? void 0 : _companyDetailData$va2.company_name;
      return companyName ? JSON.stringify([companyName]) : '';
    });
    /**
     * 申请美国买家数据库试用
     */
    const toTrial = inject(INJECT_KEY__TO_TRIAL);
    function handleDataTrial() {
      toTrial && toTrial({
        type: TRIAL_TYPE_ENUM.dataTrial
      });
      scLog('click', {
        elem: 'usdata-mining'
      });
    }
    const headerButtons = reactive([{
      txt: '挖掘联系人',
      onClick: handleDataTrial
    }]);
    // if (user.value.user_type !== ACCOUNT_TYPE.USADATAFREE) {
    //     headerButtons.push({
    //         type: 'primary',
    //         txt: '导入线索管理',
    //         loading: importing.value,
    //         onClick: handleImportLeads,
    //     });
    // }
    return {
      isLoading,
      companyDetailData,
      contacts,
      companyId,
      headerButtons,
      handleDataTrial
    };
  }
});
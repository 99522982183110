import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a15c12e6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "page-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$companyDetailDat;
  const _component_section_header = _resolveComponent("section-header");
  const _component_company_info = _resolveComponent("company-info");
  const _component_contact_info = _resolveComponent("contact-info");
  const _component_customs = _resolveComponent("customs");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_section_header, {
    title: (_ctx$companyDetailDat = _ctx.companyDetailData) === null || _ctx$companyDetailDat === void 0 ? void 0 : _ctx$companyDetailDat.company_name,
    back: "",
    buttons: _ctx.headerButtons,
    onBackError: _cache[0] || (_cache[0] = $event => _ctx.$router.push({
      name: 'UsCustomsData'
    }))
  }, null, 8, ["title", "buttons"]), _withDirectives(_createVNode(_component_company_info, {
    "company-info": _ctx.companyDetailData
  }, null, 8, ["company-info"]), [[_directive_loading, _ctx.isLoading]]), _ctx.contacts instanceof Array && _ctx.contacts.length ? (_openBlock(), _createBlock(_component_contact_info, {
    key: 0,
    class: "contact-info",
    contacts: _ctx.contacts
  }, null, 8, ["contacts"])) : _createCommentVNode("", true), _createVNode(_component_customs, {
    class: "customs",
    "company-id": _ctx.companyId
  }, null, 8, ["company-id"])]);
}
import { defineComponent } from 'vue';
import EllipsisText from '../EllipsisText.vue';
export default defineComponent({
  components: {
    EllipsisText
  },
  name: 'ListTos',
  inheritAttrs: false,
  props: {
    listData: {
      type: Array,
      default: () => []
    },
    line: {
      type: Number,
      default: 2
    },
    elPopoverProps: {
      type: Object,
      default: () => ({})
    },
    ellipsisTextProps: {
      type: Object,
      default: () => ({})
    }
  }
});
import {SOCIAL_PLATFORMS} from './config';

export function getSocialPlatform(link: string) {
    for (let item of SOCIAL_PLATFORMS) {
        if (link.includes(item)) {
            return item;
        }
    }
    return 'other';
}

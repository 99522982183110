import { computed, defineComponent, toRefs } from 'vue';
import { DEFAULT_GUTTER } from './config';
import { getFormattedGutterValue } from './utils';
export default defineComponent({
  name: 'TDescription',
  props: {
    column: {
      type: Number,
      default: 12
    },
    gutter: {
      type: [Number, Array],
      default: () => DEFAULT_GUTTER,
      validator: val => {
        if (typeof val === 'number') {
          return true;
        } else if (val instanceof Array && val.length === 2 && val.every(item => typeof item === 'number')) {
          return true;
        }
        return false;
      }
    },
    /**
     * 排列的方向
     */
    direction: {
      type: String,
      default: 'horizontal'
    }
  },
  setup(props) {
    const {
      gutter
    } = toRefs(props);
    const computedGutter = computed(() => getFormattedGutterValue(gutter.value));
    return {
      computedGutter
    };
  }
});
import { defineComponent } from 'vue';
import TTable from '@/components/TTable/index.vue';
import useTable from '@/base/hooks/useTable';
import { getBuyerdbCompanyCustomsData } from '@/base/api/buyerdb/company/methods';
import TPagination from '@/components/TPagination.vue';
import EllipsisText from '@/components/EllipsisText.vue';
export default defineComponent({
  name: 'Customs',
  components: {
    TTable,
    TPagination,
    EllipsisText
  },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      currentPage,
      currentPageSize,
      totalCount,
      tableData,
      tableLoading,
      fetchTableData
    } = useTable(getBuyerdbCompanyCustomsData, {
      filterParams: {
        companyId: props.companyId
      },
      tableDataGetter: data => data && data.recordList || [],
      pageSizeReqParam: 'pageSize',
      pageReqParam: 'pageNo',
      defaultPageSize: 10,
      routeMode: 'replace',
      ignoreRouteParams: (key, _value) => {
        if (key === 'companyId') {
          return false;
        }
        return totalCount.value > 0;
      }
    });
    function tableRowClassName() {
      return 'customs__table-row';
    }
    return {
      currentPage,
      currentPageSize,
      totalCount,
      tableData,
      tableLoading,
      fetchTableData,
      tableRowClassName
    };
  }
});
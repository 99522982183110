/**
 * @file 基于 ElPagination 的通用的表格分页组件
 * 简化了使用配置，修改了默认样式，同时可继承 ElPagination 的相关属性
 *
 *
 * 使用示例：
 * <t-pagination
 *     v-model:current-page="currentPage"
 *     v-model:page-size="pageSize"
 *     :total="totalCount"
 *     @current-change="fetchTableData"
 *     @size-change="fetchTableData"
 * />
 */
import { defineComponent } from 'vue';
import { ElPagination } from 'element-plus';
const DEFAULT_PAGE_SIZES = [10, 20, 30, 40];
const DEFAULT_LAYOUT = 'total, sizes, prev, pager, next';
export default defineComponent({
  name: 'TPagination',
  components: {
    ElPagination
  },
  inheritAttrs: false,
  props: {
    total: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    layout: {
      type: String,
      default: DEFAULT_LAYOUT
    },
    pageSizes: {
      type: Array,
      default: () => {
        return DEFAULT_PAGE_SIZES;
      }
    },
    background: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:current-page', 'update:page-size',
  // events below are depracated
  // v-model:current-page and v-model:page-size are better choices
  'size-change', 'current-change'],
  setup(props, context) {
    const handleOnSizeChange = currentSize => {
      context.emit('update:page-size', currentSize);
      context.emit('size-change', currentSize);
    };
    const handleOnCurrentChange = currentPage => {
      context.emit('update:current-page', currentPage);
      context.emit('current-change', currentPage);
    };
    return {
      handleOnSizeChange,
      handleOnCurrentChange
    };
  }
});
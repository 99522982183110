import "core-js/modules/es.array.push.js";
import { computed, createTextVNode, defineComponent, h, toRefs } from 'vue';
import { getSocialPlatform } from '../utils';
import SocialButtonGroupVue from './SocialButtonGroup.vue';
import EncryptInfoVue from './EncryptInfo.vue';
import TDescriptionItem from '@/components/TDescription/TDescriptionItem.vue';
import TDescription from '@/components/TDescription/index.vue';
import VNodeRender from '@/components/VNodeRender/index.vue';
import { createProtocolUrl } from '@/base/utils/url';
import EllipsisText from '@/components/EllipsisText.vue';
import useCountryOptions from '@/base/hooks/useCountryOptions';
import ListTos from '@/components/ListTos/index.vue';
import TImage from '@/components/TImage.vue';
import { getCompanyDefaultLogo } from '@/views/Customer/utils';
export default defineComponent({
  name: 'CompanyInfo',
  components: {
    TDescriptionItem,
    TDescription,
    VNodeRender,
    EllipsisText,
    TImage
  },
  props: {
    companyInfo: {
      type: Object,
      default: undefined
    }
  },
  setup(props) {
    const {
      companyInfo
    } = toRefs(props);
    const {
      countryOptions
    } = useCountryOptions();
    const infoConfig = computed(() => {
      var _companyInfo$value3, _countryOptions$value, _companyInfo$value5, _companyInfo$value13, _companyInfo$value22;
      // column 按比例分列 180:400:400
      const config = [{
        title: '基本信息',
        column: 49,
        info: [{
          label: '企业官网',
          span: 9,
          render: () => {
            var _companyInfo$value;
            if ((_companyInfo$value = companyInfo.value) !== null && _companyInfo$value !== void 0 && _companyInfo$value.website) {
              return h(EllipsisText, {
                tag: 'a',
                contentElProps: {
                  href: createProtocolUrl(companyInfo.value.website),
                  target: '_blank'
                }
              }, () => {
                var _companyInfo$value$we, _companyInfo$value2;
                return (_companyInfo$value$we = (_companyInfo$value2 = companyInfo.value) === null || _companyInfo$value2 === void 0 ? void 0 : _companyInfo$value2.website) !== null && _companyInfo$value$we !== void 0 ? _companyInfo$value$we : '';
              });
            }
            return createTextVNode('');
          }
        }, {
          label: '员工数量',
          value: (_companyInfo$value3 = companyInfo.value) === null || _companyInfo$value3 === void 0 ? void 0 : _companyInfo$value3.num_of_employees,
          span: 20
        }, {
          label: '所属国家',
          value: ((_countryOptions$value = countryOptions.value.find(item => {
            var _companyInfo$value4;
            return item.value === Number((_companyInfo$value4 = companyInfo.value) === null || _companyInfo$value4 === void 0 ? void 0 : _companyInfo$value4.area);
          })) === null || _countryOptions$value === void 0 ? void 0 : _countryOptions$value.label) || '',
          span: 20
        }, {
          label: '成立时间',
          value: (_companyInfo$value5 = companyInfo.value) === null || _companyInfo$value5 === void 0 ? void 0 : _companyInfo$value5.establish_date,
          span: 9
        }, {
          label: 'SIC编码',
          render: () => {
            var _companyInfo$value6, _companyInfo$value7, _companyInfo$value8, _companyInfo$value9, _companyInfo$value10, _companyInfo$value11;
            const sics = [`${((_companyInfo$value6 = companyInfo.value) === null || _companyInfo$value6 === void 0 ? void 0 : _companyInfo$value6.sic2) || ''} ${((_companyInfo$value7 = companyInfo.value) === null || _companyInfo$value7 === void 0 ? void 0 : _companyInfo$value7.category2) || ''}`, `${((_companyInfo$value8 = companyInfo.value) === null || _companyInfo$value8 === void 0 ? void 0 : _companyInfo$value8.sic3) || ''} ${((_companyInfo$value9 = companyInfo.value) === null || _companyInfo$value9 === void 0 ? void 0 : _companyInfo$value9.category3) || ''}`, `${((_companyInfo$value10 = companyInfo.value) === null || _companyInfo$value10 === void 0 ? void 0 : _companyInfo$value10.sic4) || ''} ${((_companyInfo$value11 = companyInfo.value) === null || _companyInfo$value11 === void 0 ? void 0 : _companyInfo$value11.category4) || ''}`].filter(address => address !== ' ');
            return sics.length ? h('div', null, {
              default: () => sics.map(address => h(EllipsisText, null, {
                default: () => address
              }))
            }) : createTextVNode('');
          },
          span: 20
        }, {
          label: '营业时间',
          render: () => {
            var _companyInfo$value12;
            const listData = (((_companyInfo$value12 = companyInfo.value) === null || _companyInfo$value12 === void 0 ? void 0 : _companyInfo$value12.business_hours) || []).filter(item => !!item);
            return listData.length ? h(
            // @ts-ignore
            ListTos, {
              line: 3,
              listData: listData,
              elPopoverProps: {
                // @ts-ignore
                placement: 'top-start',
                width: 'initial'
              }
            }, {
              item: params => {
                const children = [h('span', params.text)];
                if (listData.length > 3 && params.index === 2) {
                  children.push(h('span', '...'));
                }
                return h(EllipsisText, {
                  disabled: listData.length > 3 ? true : undefined
                }, children);
              }
            }) : createTextVNode('');
          },
          span: 20
        }, {
          label: '年营业额',
          value: (_companyInfo$value13 = companyInfo.value) === null || _companyInfo$value13 === void 0 ? void 0 : _companyInfo$value13.annual_revenue,
          span: 9
        }, {
          label: '产品介绍',
          span: 20,
          render: () => h(EllipsisText, {
            line: 3
          }, {
            default: () => {
              var _companyInfo$value14;
              return ((_companyInfo$value14 = companyInfo.value) === null || _companyInfo$value14 === void 0 || (_companyInfo$value14 = _companyInfo$value14.product) === null || _companyInfo$value14 === void 0 ? void 0 : _companyInfo$value14.join(' / ')) || '-';
            }
          })
        }, {
          label: '公司地址',
          render: () => {
            var _companyInfo$value15, _companyInfo$value16, _companyInfo$value17;
            const addresses = [(_companyInfo$value15 = companyInfo.value) === null || _companyInfo$value15 === void 0 ? void 0 : _companyInfo$value15.address3, (_companyInfo$value16 = companyInfo.value) === null || _companyInfo$value16 === void 0 ? void 0 : _companyInfo$value16.address2, (_companyInfo$value17 = companyInfo.value) === null || _companyInfo$value17 === void 0 ? void 0 : _companyInfo$value17.address1].filter(address => !!address);
            return addresses.length ? h('div', null, {
              default: () => addresses.map(address => h(EllipsisText, null, {
                default: () => address
              }))
            }) : createTextVNode('');
          },
          span: 20
        }]
      }, {
        title: '联系方式',
        column: 49,
        info: [{
          label: '企业电话',
          span: 9,
          render: () => {
            var _companyInfo$value18;
            return h(EncryptInfoVue, {
              info: (_companyInfo$value18 = companyInfo.value) === null || _companyInfo$value18 === void 0 ? void 0 : _companyInfo$value18.phones
            });
          }
        }, {
          label: '社交媒体',
          span: 40,
          render: () => {
            var _companyInfo$value$so, _companyInfo$value19;
            return h(SocialButtonGroupVue, {
              socials: (_companyInfo$value$so = (_companyInfo$value19 = companyInfo.value) === null || _companyInfo$value19 === void 0 ? void 0 : _companyInfo$value19.social_media.map(link => ({
                type: getSocialPlatform(link),
                link
              }))) !== null && _companyInfo$value$so !== void 0 ? _companyInfo$value$so : []
            });
          }
        }, {
          label: '公司传真',
          span: 9,
          render: () => {
            var _companyInfo$value20;
            return h(EncryptInfoVue, {
              info: (_companyInfo$value20 = companyInfo.value) === null || _companyInfo$value20 === void 0 ? void 0 : _companyInfo$value20.fax
            });
          }
        }, {
          label: '企业邮箱',
          span: 40,
          render: () => {
            var _companyInfo$value21;
            return h(EncryptInfoVue, {
              info: (_companyInfo$value21 = companyInfo.value) === null || _companyInfo$value21 === void 0 ? void 0 : _companyInfo$value21.emails
            });
          }
        }]
      }];
      if ((_companyInfo$value22 = companyInfo.value) !== null && _companyInfo$value22 !== void 0 && _companyInfo$value22.description) {
        config.push({
          title: '公司概要',
          column: 1,
          info: [{
            label: '公司描述',
            span: 1,
            render: () => {
              var _companyInfo$value$de, _companyInfo$value23;
              return createTextVNode((_companyInfo$value$de = (_companyInfo$value23 = companyInfo.value) === null || _companyInfo$value23 === void 0 ? void 0 : _companyInfo$value23.description) !== null && _companyInfo$value$de !== void 0 ? _companyInfo$value$de : '');
            }
          }]
        });
      }
      return config;
    });
    return {
      infoConfig,
      createProtocolUrl,
      getCompanyDefaultLogo
    };
  }
});
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createBlock(_component_el_pagination, _mergeProps({
    layout: _ctx.layout,
    total: _ctx.total,
    "current-page": _ctx.currentPage,
    "page-size": _ctx.pageSize,
    "page-sizes": _ctx.pageSizes,
    background: _ctx.background,
    class: "t-pagination"
  }, _ctx.$attrs, {
    onSizeChange: _ctx.handleOnSizeChange,
    onCurrentChange: _ctx.handleOnCurrentChange
  }), null, 16, ["layout", "total", "current-page", "page-size", "page-sizes", "background", "onSizeChange", "onCurrentChange"]);
}
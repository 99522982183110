import { computed, defineComponent, toRefs } from 'vue';
import { SOCIAL_PLATFORMS } from '../config';
import { createProtocolUrl } from '@/base/utils/url';
const assets = {
  other: require('@/assets/img/customer/social-icon-other.png'),
  facebook: require('@/assets/img/customer/social-icon-facebook.png'),
  linkedin: require('@/assets/img/customer/social-icon-linkedin.png'),
  youtube: require('@/assets/img/customer/social-icon-youtube.png'),
  twitter: require('@/assets/img/customer/social-icon-twitter.png')
};
export default defineComponent({
  name: 'SocialButtonGroup',
  props: {
    socials: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const {
      socials
    } = toRefs(props);
    const otherSocialPlatforms = computed(() => {
      var _socials$value$filter, _socials$value;
      return (_socials$value$filter = (_socials$value = socials.value) === null || _socials$value === void 0 ? void 0 : _socials$value.filter(item => item.type === 'other')) !== null && _socials$value$filter !== void 0 ? _socials$value$filter : [];
    });
    return {
      SOCIAL_PLATFORMS,
      assets,
      otherSocialPlatforms,
      createProtocolUrl
    };
  }
});
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0ef604e9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "social-button-group empty-placeholder"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["src", "alt"];
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "other-social-list"
};
const _hoisted_6 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_popover = _resolveComponent("el-popover");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socials, (item, index) => {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: index
    }, [item.type !== 'other' ? (_openBlock(), _createElementBlock("a", {
      key: 0,
      href: _ctx.createProtocolUrl(item.link),
      target: "_blank"
    }, [_createElementVNode("img", {
      src: _ctx.assets[item.type],
      alt: item.type
    }, null, 8, _hoisted_3)], 8, _hoisted_2)) : _createCommentVNode("", true)], 64);
  }), 128)), _ctx.otherSocialPlatforms.length ? (_openBlock(), _createBlock(_component_el_popover, {
    key: 0,
    width: "initial",
    placement: "right",
    trigger: "hover"
  }, {
    reference: _withCtx(() => [_createElementVNode("img", {
      src: _ctx.assets.other,
      alt: "other social"
    }, null, 8, _hoisted_4)]),
    default: _withCtx(() => [_createElementVNode("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherSocialPlatforms, (item, index) => {
      return _openBlock(), _createElementBlock("li", {
        key: index
      }, [_createElementVNode("a", {
        href: _ctx.createProtocolUrl(item.link),
        target: "_blank"
      }, _toDisplayString(item.link), 9, _hoisted_6)]);
    }), 128))])]),
    _: 1
  })) : _createCommentVNode("", true)]);
}
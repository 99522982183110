import {DEFAULT_GUTTER} from './config';

export function getFormattedGutterValue(val: any): [number, number] {
    if (typeof val === 'number') {
        return [val, val];
    }
    else if (val instanceof Array && val.length >= 2 && val.every(item => typeof item === 'number')) {
        return [val[0], val[1]] as [number, number];
    }
    return DEFAULT_GUTTER;
}

import { defineComponent } from 'vue';
import EncryptInfo from './EncryptInfo.vue';
import TDescriptionItem from '@/components/TDescription/TDescriptionItem.vue';
import TDescription from '@/components/TDescription/index.vue';
import EllipsisText from '@/components/EllipsisText.vue';
import { createProtocolUrl } from '@/base/utils/url';
import ListTos from '@/components/ListTos/index.vue';
export default defineComponent({
  name: 'ContactInfo',
  components: {
    TDescriptionItem,
    TDescription,
    EllipsisText,
    EncryptInfo,
    ListTos
  },
  props: {
    contacts: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    return {
      createProtocolUrl
    };
  }
});
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1f5e6433"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "list-tos-popover"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$listData;
  const _component_ellipsis_text = _resolveComponent("ellipsis-text");
  const _component_el_popover = _resolveComponent("el-popover");
  return _openBlock(), _createBlock(_component_el_popover, _mergeProps({
    placement: "right",
    trigger: "hover",
    disabled: ((_ctx$listData = _ctx.listData) === null || _ctx$listData === void 0 ? void 0 : _ctx$listData.length) <= _ctx.line
  }, _ctx.elPopoverProps, {
    width: "auto",
    "popper-class": "list-tos-popper"
  }), {
    reference: _withCtx(() => {
      var _ctx$listData2;
      return [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx$listData2 = _ctx.listData) === null || _ctx$listData2 === void 0 ? void 0 : _ctx$listData2.slice(0, _ctx.line), (text, index) => {
        return _renderSlot(_ctx.$slots, "item", {
          key: text,
          text: text,
          index: index
        }, () => {
          var _ctx$listData3;
          return [_createVNode(_component_ellipsis_text, _mergeProps({
            disabled: ((_ctx$listData3 = _ctx.listData) === null || _ctx$listData3 === void 0 ? void 0 : _ctx$listData3.length) > _ctx.line
          }, _ctx.ellipsisTextProps), {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(text) + _toDisplayString(index === _ctx.listData.length - 1 && _ctx.listData.length > _ctx.line ? ' ...' : ''), 1)]),
            _: 2
          }, 1040, ["disabled"])];
        }, true);
      }), 128))])];
    }),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (text, index) => {
      return _openBlock(), _createElementBlock("p", {
        key: text,
        class: "table__column--text"
      }, [_renderSlot(_ctx.$slots, "popover-item", {
        text: text,
        index: index
      }, () => [_createTextVNode(_toDisplayString(text), 1)], true)]);
    }), 128))])]),
    _: 3
  }, 16, ["disabled"]);
}
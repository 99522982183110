import "core-js/modules/es.array.push.js";
import { defineComponent, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import EllipsisText from './EllipsisText.vue';
import TButton from '@/components/TButton/index.vue';
/**
 * @file 每个模块的标题部分，支持加入标题、操作按钮、返回键
 */
export default defineComponent({
  name: 'SectionHeader',
  props: {
    back: {
      type: [Boolean, Function, Object],
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    buttons: {
      type: Array,
      default: () => []
    },
    disableFrom: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TButton,
    EllipsisText
  },
  emits: [
  /**
   * 当返回失败时触发
   */
  'back-error'],
  setup(props, {
    emit
  }) {
    const {
      back,
      disableFrom
    } = toRefs(props);
    const router = useRouter();
    const route = useRoute();
    const handleBackBtnClick = () => {
      if (back.value) {
        var _route$query;
        if (typeof ((_route$query = route.query) === null || _route$query === void 0 ? void 0 : _route$query.from) === 'string' && !disableFrom.value) {
          router.push({
            path: route.query.from
          });
        } else if (typeof back.value === 'function') {
          back.value();
        } else if (typeof back.value === 'object') {
          router.push(back.value);
        } else {
          if (window.history.length > 1) {
            router.back();
          } else {
            emit('back-error');
          }
        }
      }
    };
    return {
      handleBackBtnClick
    };
  }
});
import { computed, defineComponent, toRefs } from 'vue';
import EllipsisText from '@/components/EllipsisText.vue';
export default defineComponent({
  name: 'EncryptInfo',
  components: {
    EllipsisText
  },
  props: {
    info: {
      type: [Array, String],
      default: undefined
    }
  },
  setup(props) {
    const {
      info
    } = toRefs(props);
    const infos = computed(() => {
      if (info.value instanceof Array) {
        return info.value.filter(item => !!item);
      }
      if (info.value) {
        return [info.value].filter(item => !!item);
      }
      return [];
    });
    return {
      infos
    };
  }
});
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$gutter$, _ctx$gutter, _ctx$gutter$2, _ctx$gutter2;
  const _component_ellipsis_text = _resolveComponent("ellipsis-text");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['t-description-item', _ctx.computedDirection === 'vertical' ? 'vertical-layout' : null]),
    style: _normalizeStyle({
      width: _ctx.itemWidth,
      padding: `${(_ctx$gutter$ = (_ctx$gutter = _ctx.gutter) === null || _ctx$gutter === void 0 ? void 0 : _ctx$gutter[0]) !== null && _ctx$gutter$ !== void 0 ? _ctx$gutter$ : 8}px ${(_ctx$gutter$2 = (_ctx$gutter2 = _ctx.gutter) === null || _ctx$gutter2 === void 0 ? void 0 : _ctx$gutter2[1]) !== null && _ctx$gutter$2 !== void 0 ? _ctx$gutter$2 : 10}px`
    })
  }, [_createElementVNode("div", {
    class: _normalizeClass(['t-description-item__label', _ctx.labelClassName])
  }, _toDisplayString(_ctx.label) + ":", 3), _createElementVNode("div", {
    class: _normalizeClass(['t-description-item__content empty-placeholder', _ctx.contentClassName])
  }, [_ctx.childrenIsString && _ctx.showOverflowTooltip ? (_openBlock(), _createBlock(_component_ellipsis_text, {
    key: 0,
    line: _ctx.contentMaxLine,
    placement: "top",
    "content-class": "empty-placeholder"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }, 8, ["line"])) : _renderSlot(_ctx.$slots, "default", {
    key: 1
  }, undefined, true)], 2)], 6);
}
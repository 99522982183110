import "core-js/modules/es.error.cause.js";
import { computed, defineComponent, getCurrentInstance, Text, toRefs } from 'vue';
import EllipsisText from '../EllipsisText.vue';
import { getFormattedGutterValue } from './utils';
export default defineComponent({
  components: {
    EllipsisText
  },
  name: 'TDescriptionItem',
  props: {
    label: {
      type: String,
      required: true
    },
    span: {
      type: Number,
      default: undefined
    },
    labelClassName: {
      type: String,
      default: undefined
    },
    contentClassName: {
      type: String,
      default: undefined
    },
    showOverflowTooltip: {
      type: Boolean,
      default: false
    },
    contentMaxLine: {
      type: Number,
      default: 1
    },
    width: {
      type: String,
      default: undefined
    },
    direction: {
      type: String,
      default: undefined
    }
  },
  setup(props, {
    slots
  }) {
    const {
      width,
      span,
      direction
    } = toRefs(props);
    const parentComponent = computed(() => {
      var _getCurrentInstance;
      const parent = (_getCurrentInstance = getCurrentInstance()) === null || _getCurrentInstance === void 0 ? void 0 : _getCurrentInstance.parent;
      if ((parent === null || parent === void 0 ? void 0 : parent.type.name) !== 'TDescription') {
        throw new Error('TDescriptionItem 组件仅能在 TDescription 组件下使用');
      }
      return parent;
    });
    const column = computed(() => {
      var _parentComponent$valu, _parentComponent$valu2;
      return (_parentComponent$valu = (_parentComponent$valu2 = parentComponent.value.props) === null || _parentComponent$valu2 === void 0 ? void 0 : _parentComponent$valu2.column) !== null && _parentComponent$valu !== void 0 ? _parentComponent$valu : 12;
    });
    const gutter = computed(() => {
      var _parentComponent$valu3;
      return getFormattedGutterValue((_parentComponent$valu3 = parentComponent.value) === null || _parentComponent$valu3 === void 0 || (_parentComponent$valu3 = _parentComponent$valu3.props) === null || _parentComponent$valu3 === void 0 ? void 0 : _parentComponent$valu3.gutter);
    });
    const itemWidth = computed(() => {
      if (typeof width.value === 'string') {
        return width.value;
      }
      if (typeof span.value !== 'number') {
        throw new Error('TDescriptionItem 组件的 width 与 span 属性至少传入一个');
      }
      return `${span.value / column.value * 100}%`;
    });
    const computedDirection = computed(() => {
      var _ref, _parentComponent$valu4;
      return (_ref = direction.value || ((_parentComponent$valu4 = parentComponent.value.props) === null || _parentComponent$valu4 === void 0 ? void 0 : _parentComponent$valu4.direction)) !== null && _ref !== void 0 ? _ref : 'horizontal';
    });
    const children = computed(() => slots !== null && slots !== void 0 && slots.default ? slots.default() : []);
    const childrenIsString = computed(() => children.value.every(child => child.type === Text));
    return {
      gutter,
      itemWidth,
      childrenIsString,
      computedDirection
    };
  }
});
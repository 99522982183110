import {API, IAxiosRequestConfig} from '@/base/http';
import {
    IAreasRes, IPhoneReq, ISubmitJoinusReq, ICountryItem, ISubmitPartnerReq,
    ISubmitFreetrialReq,
} from '@/base/api/interface/common';

export const PATHS = {
    areas: '/area',
    captcha: '/index/captcha',
    submitJoinus: '/index/leads',
    country: '/area/country',
    submitPartner: 'participate/create',
    submitFreetrial: '/waimao/mall/open/service/proxy/saas/openapi/portal/service/index/trial',
};

export const LOG_API_PATHS = {
    areas: '/openapi/portal/service/area',
    captcha: '/openapi/portal/service/index/captcha',
    submitJoinus: '/openapi/portal/service/index/leads',
    country: '/openapi/portal/service/area/country',
    submitPartner: '/openapi/portal/service/participate/create',
};

/**
 * 获取全国的地区配置项
 * api: http://ku.baidu-int.com/knowledge/HFVrC7hq1Q/pKzJfZczuc/9nUaBjaVOI/3qgD3JzFZcThbn
 */
export const fetchAreas = () => {
    return API.get<{area: IAreasRes[]}>(PATHS.areas, {baseURL: process.env.VUE_APP_COMMON_API,
        cached: 'sessionStorage'});
};

/**
 * 获取手机验证码
 * api: https://yapi.baidu-int.com/project/25966/interface/api/1444399
 */
export const fetchCaptcha = (params: IPhoneReq) => {
    return API.get(PATHS.captcha, {data: params});
};

/**
 * 立即入驻
 * @api: https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/3kyfr_8TGU/9nUaBjaVOI/CIng0v6d4rl120
 */
export const fetchSubmitJoinus = (params: ISubmitJoinusReq, config?: IAxiosRequestConfig) => {
    return API.post(PATHS.submitJoinus, {...config, data: params});
};

/**
 * 获取国家列表
 * @api https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/3kyfr_8TGU/9nUaBjaVOI/3qgD3JzFZcThbn
 */
export const getCountryList = () => {
    return API.get<ICountryItem[]>(PATHS.country, {baseURL: process.env.VUE_APP_COMMON_API, cached: 'sessionStorage'});
};

/**
 * 合作伙伴提交
 * @api https://ku.baidu-int.com/knowledge/HFVrC7hq1Q/3kyfr_8TGU/9nUaBjaVOI/NKoH2W7bZAkKEQ
 */
export const fetchSubmitPartner = (params: ISubmitPartnerReq, config?: IAxiosRequestConfig) => {
    return API.post(PATHS.submitPartner, {...config, data: params});
};

/**
 * 试用申请
 * @api https://yapi.baidu-int.com/project/25966/interface/api/1859460
 */
export const fetchSubmitFreetrial = (params: ISubmitFreetrialReq, config?: IAxiosRequestConfig) => {
    return API.post(PATHS.submitFreetrial, {...config, baseURL: '/nodeapi/auth/validate/proxy', data: params});
};

import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-176496c0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "t-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "t-description__content",
    style: _normalizeStyle({
      margin: `-${_ctx.computedGutter[0]}px -${_ctx.computedGutter[1]}px`
    })
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)], 4)]);
}